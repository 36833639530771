@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .navLink {
    @apply w-full flex items-center gap-4 text-white font-semibold py-[12px] px-[50px] rounded-xl transition;
  }

  .focusBtn {
    @apply focus:outline focus:outline-offset-2 focus:outline-2 outline-[#6a55fa];
  }

  .clamp {
    @apply text-ellipsis	overflow-hidden;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 14px;
}

/* Track */
::-webkit-scrollbar-track {
  /* border-radius: 100vh; */
  background: #111827;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1f2937;
  border-radius: 100vh;
  border: 2px solid #152232;
}

::-webkit-scrollbar-thumb:hover {
  background: #374151;
}
